import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import { PublicLayout } from "./layouts/publicLayout";
import RoutesList from "./routes/routes";
import PrivateLayout from "./layouts/privateLayout";
const Login = lazy(() => import("./pages/auth/Login"));
const Home = lazy(() => import("../src/pages/Home/Home"));

const NotFound = lazy(() => import("./pages/error/NotFound"));
import { useSelector, useDispatch } from "react-redux";
import {
	Toastr,
	ScrollTop,
	AppDispatch,
	setMessage,
	setLoading,
	logoutProcure,
} from "@karkhanaui/react";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Mixpanel } from "./Mixpanel.js";
import { FormDataProvider } from "./pages/Rfq/FormDataProvider";
import {
	ThemeProvider,
	theme,
	ColorModeProvider,
	CSSReset,
} from "@chakra-ui/react";
import { datadogRum } from "@datadog/browser-rum";
import Cookies from "js-cookie";
import { datadogLogs } from "@datadog/browser-logs";
import { PurchaseOrderProvider } from "./pages/PurchaseOrder/PurchaseOrderProvider";

const environment = process.env.REACT_APP_NODE_ENV;
//GTM
const tagManagerArgs = {
	production: {
		gtmId: "GTM-MH7QVBV",
		gtmAuth: "XjDXBMxpQaspJ-JchCcHjg",
	},
	development: {
		gtmId: "GTM-MH7QVBV",
		gtmAuth: "2l-z28VNVTsuakjwXgwzjg",
	},
	// Add more environments as needed
};
TagManager.initialize({
	gtmId: tagManagerArgs[environment]?.gtmId,
	dataLayer: { gtmAuth: tagManagerArgs[environment]?.gtmAuth },
});

// --------------------DataDog implementation--------------------
const dataDodArgs = {
	production: {
		applicationId: "3490a028-689d-45f6-98d4-cb5990305f42",
		clientToken: "pubcdf3200cef8c615bc62a9ea45010732d",
		service: "procurement_frontend_prod",
	},
	development: {
		applicationId: "8cfc18c3-2b6b-43d9-a7fc-20f843e64e7d",
		clientToken: "pub172f9357ca115fdd285d4d93fe3b9353",
		service: "procurement_frontend_dev",
	},
};
if (environment !== "development") {
	datadogRum.setUser({
		name: Cookies.get("full_name") || "Guest User",
		email: Cookies.get("user_id") || "Guest Email",
	});
	datadogRum.init({
		applicationId: dataDodArgs[environment]?.applicationId ? dataDodArgs[environment].applicationId : "",
		clientToken: dataDodArgs[environment]?.clientToken ? dataDodArgs[environment]?.clientToken : "",
		site: "datadoghq.com",
		service: dataDodArgs[environment]?.service,
		env: window.location.hostname.split(".")[0],
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	});
	datadogLogs.init({
		clientToken: dataDodArgs[environment]?.clientToken,
		site: "datadoghq.com",
		forwardErrorsToLogs: true,
		sessionSampleRate: 100,
		env: environment,
		service: "PROCUREMENT",
	});

	datadogRum.startSessionReplayRecording();
}
// --------------------DataDog implementation--------------------

const App: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [authState] = useSelector((state: any) => {
		return [state.authProcure];
	});
	let isLoggedIn = authState.isLoggedIn;
	const toastMsg = useSelector((state: any) => state.message);
	Mixpanel.track_pageview({
		email: authState.user,
		id: authState.uniqueUserId,
	});

	useEffect(() => {
		if (toastMsg?.message?.data) {
			setTimeout(() => {
				dispatch(setMessage(""));
			}, 3000);
		}
	}, [toastMsg]);
	axios.interceptors.response.use(
		function (response: AxiosResponse) {
			dispatch(setLoading(false));
			return response;
		},
		function (error: AxiosError) {
			if (error.response?.status === 403) {
				if (isLoggedIn) {
					dispatch(logoutProcure());
				}
			}
			dispatch(setLoading(false));
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (isLoggedIn && environment !== "development") {
			//setting  the logged in user session
			datadogRum.setUser({
				name: Cookies.get("full_name"),
				email: Cookies.get("user_id"),
			});
		}
	}, [isLoggedIn]);

	return (
		<>
			<ThemeProvider theme={theme}>
				<ColorModeProvider>
					<CSSReset />
					<FormDataProvider>
					<PurchaseOrderProvider>
						<BrowserRouter>
							<ScrollTop />
							<Routes>
								{RoutesList.filter((route) => route.type === "public").map(
									(route, index) =>
										isLoggedIn ? (
											<Route
												key={index}
												path="/home"
												element={
													<Suspense>
														<PrivateLayout Component={Home} />
													</Suspense>
												}
											/>
										) : (
											<Route
												key={index}
												path={route.link}
												element={
													<Suspense>
														<PublicLayout Component={route.component} />
													</Suspense>
												}
											/>
										)
								)}
								{RoutesList.filter((route) => route.type === "private").map(
									(route, index) =>
										isLoggedIn ? (
											<Route
												key={index}
												exact
												path={route.link}
												element={
													<Suspense>
														<PrivateLayout Component={route.component} />
													</Suspense>
												}
											/>
										) : (
											<Route
												key={index}
												path="/login"
												element={
													<Suspense>
														<PublicLayout component={Login} />
													</Suspense>
												}
											/>
										)
								)}
								{isLoggedIn ? (
									<>
										{RoutesList.filter(
											(route: any) => route.type === "public"
										).map((route: any, index: number) => (
											<Route
												key={index}
												// exact
												path={route.link}
												element={
													<Suspense>
														<Navigate to="/home" replace={false} />
													</Suspense>
												}
											/>
										))}
										<Route
											path="*"
											element={
												<Suspense>
													<PrivateLayout Component={NotFound} />
												</Suspense>
											}
										/>
										<Route
											path="/"
											element={
												<Suspense>
													<Navigate to="/home" replace={false} />
												</Suspense>
											}
										/>
									</>
								) : (
									<Route
										path="*"
										element={
											<Suspense>
												<Navigate to="/login" replace={false} />
											</Suspense>
										}
									/>
								)}
							</Routes>
						</BrowserRouter>
						</PurchaseOrderProvider>
					</FormDataProvider>
					{toastMsg?.message?.data && <Toastr toastMsg={toastMsg} />}
				</ColorModeProvider>
			</ThemeProvider>
		</>
	);
};
export default App;
