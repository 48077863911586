import * as React from "react";
import { Box, Text, Flex, Radio } from "@chakra-ui/react";

interface AddressProps {
  data: {
    address_title: string;
    address_line1: string;
    address_line2: string;
    country: string;
    state: string;
    city: string;
    pincode: number;
    phone: string;
  };
}
export const SelectAddressCard = (data: AddressProps) => {
  const address = data.data;
  const addressTitle = address.address_title ? address.address_title : "";
  const addressLine1 = address.address_line1 ? address.address_line1 : "";
  const addressLine2 = address.address_line2 ? address.address_line2 : "";
  const country = address.country ? address.country : "";
  const state = address.state ? address.state : "";
  const city = address.city ? address.city : "";
  const pincode = address.pincode ? address.pincode : "";
  const phone = address.phone ? address.phone : "";

  return (
    <Box>
      <Text
        textStyle="primary.paragraph"
        fontWeight="bold"
        color="rgba(0, 0, 0, 0.7)"
      >
        {addressTitle}
      </Text>
      <Text textStyle="primary.paragraph" color="rgba(0, 0, 0, 0.7)">
        {addressLine1 + ", "}
      </Text>
      <Text textStyle="primary.paragraph" color="rgba(0, 0, 0, 0.7)">
        {addressLine2 + ", "}
      </Text>
      <Text textStyle="primary.paragraph" color="rgba(0, 0, 0, 0.7)">
        {city + ", " + state + ", " + country + ", " + pincode}
      </Text>
      <Text textStyle="primary.paragraph" color="rgba(0, 0, 0, 0.7)" mt="10px">
        Mobile number : {phone}
      </Text>
    </Box>
  );
};
