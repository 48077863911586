import { Box, Flex, Img, Tag, Text } from '@chakra-ui/react';
import { BasicDialog, ThemeButton } from '@karkhanaui/react';
import React from 'react';
import NDA from '../../img/nda.svg';
import FileCard from './FileCard';
import { UploadPopup } from './UploadPopup';

interface NDAUploadCardProps {
  status: any;
  supplier_email: any;
  setUploadData: (data: any) => void;
  supplierName: any;
  file: any;
  getUploadDocData: () => void;
  uploadData: any;
  deleteFunction : ()=>void;
}

export const NDAUploadCard: React.FC<NDAUploadCardProps> = ({
  status,
  supplier_email,
  setUploadData,
  file,
  supplierName,
  getUploadDocData,
  uploadData,
  deleteFunction
}: NDAUploadCardProps) => {
 

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [statusNda, setStatus] = React.useState<any>("")
  // Default status to "NA" if uploadData?.nda_status is not available
  const ndaStatus = uploadData?.nda_status || "NA" || statusNda;
  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options); // 'en-GB' for dd/mm/yyyy format
  };
  return (
    <Box p="1rem" borderRadius={"md"} width={"100%"} height="100%">
      {(ndaStatus !== "Pending" && ndaStatus !== "Signed") ? (
        <React.Fragment>
          <Text fontWeight={"600"} fontSize={"16px"} fontFamily={"Rubik"}> Non Disclosure Agreement </Text>
          <Text fontSize={"15px"} fontWeight={500} color={"rgba(33, 33, 33, 0.5)"}>
            Upload your NDA and receive signed copy from the supplier.
          </Text>
          <ThemeButton mt="0.3em" size="sm" bg="#0087EE" children="Upload NDA" onClick={() => setIsOpen(true)} />
          <Img src={NDA} float="right" objectFit={"contain"} maxWidth="100%" maxHeight="80%" width="auto" height="60%" mt="1em" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Flex flexDir="row" justifyContent="space-between">
            <Text fontWeight={"600"} fontSize={"16px"} fontFamily={"Rubik"}> Non Disclosure Agreement </Text>
            <Tag bg={ndaStatus === "Signed" ? "rgba(232, 255, 243, 1)" : "rgba(253, 185, 19, 0.2)"} color={ndaStatus === "Signed" ? "rgba(80, 184, 72, 1)" : "rgba(235, 167, 0, 1)"}>
              {ndaStatus}
            </Tag>
          </Flex>
          <Text fontSize={"15px"} fontWeight={500} color={"rgba(33, 33, 33, 0.5)"}>
            {ndaStatus === "Signed" ? `Signed NDA shared by supplier.` : `Your NDA is uploaded. The supplier has received it and will share a signed copy soon.`}
            <FileCard uploadedData = {uploadData} signed_on={uploadData?.signed_on ? uploadData?.signed_on :formatDate( uploadData?.modified)} status={ndaStatus} fileData={file} deleteFunction = {deleteFunction}  />
          </Text>
        </React.Fragment>
      )}
      <BasicDialog
        size="2xl"
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        showCloseButton={true}
        header={"Upload NDA"}
        content={
          <UploadPopup  setStatus={status} fileId={file?.name} sId={uploadData?.name} setIsOpen={setIsOpen} getUploadDocData={getUploadDocData} supplier_email={supplier_email} setUploadData={setUploadData} supplierName={supplierName} />
        }
      />
    </Box>
  );
};
