import { Box, Flex, Grid, GridItem, Img, Text } from "@chakra-ui/react";
import React from "react";
import pdf from "../../img/pdf.svg";
import { FiDownload } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { BasicDialog } from "@karkhanaui/react";
import DeleteNDA from "./DeleteNDA";
import { isEmpty } from "lodash";

import {
  AiOutlineFilePdf,
  AiOutlineFileWord,
  AiOutlineFile,
} from "react-icons/ai";
interface fileCardProps {
  status: string;
  fileData: any;
  deleteFunction: () => void;
  signed_on: any;
  uploadedData: any;
}
const FileCard = ({
  status,
  fileData,
  deleteFunction,
  signed_on,
  uploadedData,
}: fileCardProps) => {
  const [isDeletePopup, setIsDeletePopup] = React.useState<boolean>(false);
  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options); // 'en-GB' for dd/mm/yyyy format
  };

  function bytesToMegabytes(bytes) {
    return (bytes / (1024 * 1024)).toFixed(3);
  }
  const getFileIcon = (fileName) => {
    const fileExtension = fileName?.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return <Img src={pdf} height="30px" width="30px" />;
      case "doc":
        return <AiOutlineFileWord color="#0000FF" size="30px" />;
      case "docx":
        return <AiOutlineFileWord color="#0000FF" size="30px" />;
      default:
        return <AiOutlineFile color="#000" size="30px" />;
    }
  };
  console.log({ uploadedData });
  const extractAndTrimFileName = (filePath) => {
    const index = filePath.indexOf("/files/");
    if (index === -1) return filePath;

    const fullFileName = filePath.substring(index + 7);
    const lastDotIndex = fullFileName.lastIndexOf(".");

    if (lastDotIndex === -1) return fullFileName; // No extension found

    const fileName = fullFileName.substring(0, lastDotIndex);
    const extension = fullFileName.substring(lastDotIndex);

    const trimmedFileName =
      fileName.length > 15 ? fileName.substring(0, 10) + "..." : fileName;

    return `${trimmedFileName}${extension}`;
  };
  function sanitizeFileName(fileName) {
    // Replace any special characters or reserved characters with underscores
    return fileName.replace(/[\/\\:*\?"<>\|]/g, "_");
  }
  const fileName =
    status == "Signed" &&
    (uploadedData.nda_doc_name == "" ||
      uploadedData.nda_doc_name == null ||
      uploadedData.nda_doc_name == undefined)
      ? extractAndTrimFileName(uploadedData.nda_file)
      : status == "Pending"
      ? extractAndTrimFileName(uploadedData.nda_file)
      : status == "Signed" && uploadedData?.nda_doc_name?.length > 0
      ? extractAndTrimFileName(uploadedData.signed_nda_file_name)
      : null;

  return (
    <Box
      mt="1em"
      width={"100%"}
      height={"100%"}
      p="1em"
      border="1px dashed #0087ee"
      sx={{
        borderImage: "none",
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: "#0087ee",
        borderRadius: "4px",
        borderDasharray: "5, 5",
      }}
    >
      <Flex flexDir={"row"} justifyContent={"space-between"}>
        <Flex flexDir={"row"} gap="0.5em">
          {/* <Img src={pdf} height="30px" width="30px" /> */}
          <Box>{getFileIcon(fileName)}</Box>
          <Flex flexDir={"column"} gap="0.2em">
            <Text fontWeight={600} fontSize={"16px"} color="#0087ee">
              {fileName}
            </Text>
            {/* <Text
              color="rgba(181, 181, 195, 1)"
              fontWeight={500}
              fontSize={"13px"}
            >
              {bytesToMegabytes(fileData?.file_size)} Mb
            </Text> */}
            <Text
              color="rgba(181, 181, 195, 1)"
              fontWeight={500}
              fontSize={"13px"}
            >
              {status === "Signed"
                ? `Signed on ${signed_on}`
                : `Uploaded on ${formatDate(
                    uploadedData?.uploaded_on != null
                      ? uploadedData?.uploaded_on
                      : fileData?.modified
                  )}`}
            </Text>
          </Flex>
        </Flex>
        <Flex gap={"0.5em"}>
          <Box
            h="29px"
            w="29px"
            bg="rgba(0, 135, 238, 0.05)"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            onClick={() => {
              console.log(status === "Signed" && !!uploadedData.nda_doc_name && !isEmpty(uploadedData.nda_doc_name))
              
              
              if (status === "Signed" && !!uploadedData.nda_doc_name && !isEmpty(uploadedData.nda_doc_name)) {
                window.open(
                    `${process.env.REACT_APP_BASE_URL}/api/method/crosslink.nda.get_nda_from_supplier_portal?docname=${uploadedData?.nda_doc_name}`
                  );
                } else {
                  console.log(`${process.env.REACT_APP_BASE_URL}${sanitizeFileName(
                      uploadedData?.nda_file
                    )}`)
                  window.open(
                    `${process.env.REACT_APP_BASE_URL}/${uploadedData?.nda_file}`
                  );
                }
            }}
          >
            <FiDownload color="#0087ee" />
          </Box>
          {status == "Signed" && (
            <Box
              h="29px"
              w="29px"
              bg="rgba(0, 135, 238, 0.05)"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
            >
              <IoTrashOutline
                color="#0087ee"
                onClick={() => setIsDeletePopup(!isDeletePopup)}
              />
            </Box>
          )}
        </Flex>
      </Flex>
      <BasicDialog
        isOpen={isDeletePopup}
        onClose={() => setIsDeletePopup(!isDeletePopup)}
        showCloseButton={true}
        header="Delete NDA"
        content={<DeleteNDA deleteFunction={deleteFunction} />}
      />
    </Box>
  );
};

export default FileCard;
