import {
  Input,
  Text,
  Box,
  InputGroup,
  InputRightElement,
  Select,
  InputLeftAddon,
  Checkbox,
  Textarea,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ErrorMessage, useField } from "formik";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const RenderTextField = ({
  label,
  placeholder,
  disabled,
  isMandatory,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const [show, setShow] = React.useState<any>(true);
  const handleClick = () => setShow(!show);
  // const [value, setValue] = React.useState<any>("");
  useEffect(() => {
    if (props.type === "password") {
      setShow(false);
    }
  }, []);
  return (
    <Box>
      <Flex alignItems="center" justifyContent="flex-start" gap=".5rem">
        {label && (
          <Text
            textStyle="primary.text"
            sx={{ marginBottom: "0.3rem" }}
            fontSize={props.labelsize}
            fontWeight={props.labelweight}
          >
            {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
          </Text>
        )}
        {props?.infoAvailable && (
          <Tooltip label={props?.infolabel}>
            <span>
              <BsInfoCircle size="1.125rem" />
            </span>
          </Tooltip>
        )}
      </Flex>
      <InputGroup>
        {props.type === "contactNumber" && (
          <InputLeftAddon children={props.left_element} />
        )}
        <Input
          placeholder={placeholder || label}
          {...field}
          {...props}
          isInvalid={meta.touched && meta.error}
          isReadOnly={disabled}
          fontSize={{ xs: "xs", lg: "sm" }}
          // type={show ? props.type || "text" : "password"}
          type={props.type === "password" && show ? "text" : props.type}
          variant={props.variant || "filled"}
          // value={value}
          // onChange={(e) => {
          // 	if (e.target.value.length === 1) {
          // 		if (e.target.value.trim() === "") e.target.value = "";
          // 	}
          // 	setValue(e.target.value);
          // }}
        />
        {props.type === "password" && (
          <InputRightElement width="4.5rem">
            {show ? (
              <FaRegEyeSlash onClick={handleClick} />
            ) : (
              <FaRegEye onClick={handleClick} />
            )}
          </InputRightElement>
        )}
      </InputGroup>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};

export const RenderDropDown = ({
  label,
  values,
  placeholder,
  disabled,
  isMandatory,
  defaultValue,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  return (
    <Box>
      {label && (
        <Text
          textStyle="primary.text"
          sx={{ marginBottom: "0.3rem" }}
          fontSize={props.labelsize}
          fontWeight={props.labelweight}
        >
          {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Select
        {...field}
        {...props}
        isInvalid={meta.touched && meta.error}
        variant={props.variant || "filled"}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        <option value="">Select</option>
        {values?.map((item: any) => {
          return (
            <option value={item} key={item}>
              {item}
            </option>
          );
        })}
      </Select>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};
export const RenderCheckboxField: React.FC<any> = ({
  label,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <Box>
      <Checkbox {...field} {...props}>
        {label && (
          <Text
            fontSize={{ xs: "xs", lg: "sm" }}
            fontWeight={props.labelweight}
          >
            {label}
          </Text>
        )}
      </Checkbox>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};

export const RenderDropdownField: React.FC<any> = ({
  options,
  label,
  disabled,
  isMandatory,
  value,
  handleDropdownChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [selected, setSelected] = React.useState("");
  const [allFormValues, setAllFormValues] = React.useState({});

  return (
    <Box>
      {label && (
        <Text
          sx={{ marginBottom: "0.3rem" }}
          fontSize={props.labelsize || "xs"}
          fontWeight={props.labelweight}
        >
          {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Select
        {...field}
        {...props}
        placeholder="Select"
        value={value}
        onChange={handleDropdownChange}
      >
        {options?.map((item: any) => (
          <option key={item}>{item}</option>
        ))}
      </Select>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};

export const RenderCountryStateDropdown = ({
  label,
  values,
  placeholder,
  disabled,
  isMandatory,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  return (
    <Box>
      {label && (
        <Text
          textStyle="primary.text"
          sx={{ marginBottom: "0.3rem" }}
          fontSize={props.labelsize}
          fontWeight={props.labelweight}
        >
          {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Select
        {...field}
        {...props}
        isInvalid={meta.touched && meta.error}
        isReadOnly={disabled}
        variant="filled"
        // defaultValue="IN"
        value={props.value}
        onChange={(e) => props?.onChange(e.target.value)}
      >
        <option value="">Select</option>

        {values?.map((item: any) => {
          return (
            <option
              value={item.isoCode ? item.isoCode : item.name}
              key={item.isoCode ? item.isoCode : item.name}
            >
              {item.name}
            </option>
          );
        })}
      </Select>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};
export const RenderPhoneField: React.FC<any> = ({
  options,
  label,
  disabled,
  isMandatory,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <Box>
      {label && (
        <Text
          textStyle="primary.text"
          sx={{ marginBottom: "0.3rem" }}
          fontSize={props.labelsize}
          fontWeight={props.labelweight}
        >
          {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <PhoneInput
        isInvalid={meta.touched && meta.error}
        enableSearch={true}
        {...field}
        {...props}
        inputStyle={{
          width: "100%",
        }}
        country="in"
        value={field.value}
        international
        onChange={(value) => {
          helpers.setValue(value);
          if (props.onPhoneChange) {
            props.onPhoneChange(value);
          }
        }}
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};

export const RenderTextAreaField = ({
  label,
  placeholder,
  disabled,
  isMandatory,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  const [show, setShow] = React.useState<any>(true);
  const handleClick = () => setShow(!show);
  useEffect(() => {
    if (props.type === "password") {
      setShow(false);
    }
  }, []);
  return (
    <Box>
      {label && (
        <Text
          textStyle="primary.text"
          sx={{ marginBottom: "0.3rem" }}
          fontSize={props.labelsize}
          fontWeight={props.labelweight}
        >
          {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Textarea
        placeholder={placeholder || label}
        {...field}
        {...props}
        isInvalid={meta.touched && meta.error}
        isReadOnly={disabled}
        fontSize={{ xs: "xs", lg: "sm" }}
      ></Textarea>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};

export const RenderTextFieldWithCustomRightElement = ({
  label,
  placeholder,
  disabled,
  isMandatory,
  rightelement,
  ...props
}: any) => {
  const [field, meta] = useField(props);
  return (
    <Box>
      {label && (
        <Text
          textStyle="primary.text"
          sx={{ marginBottom: "0.3rem" }}
          fontSize={props.labelsize}
          fontWeight={props.labelweight}
        >
          {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <InputGroup>
        <Input
          placeholder={placeholder || label}
          {...field}
          {...props}
          isInvalid={meta.touched && meta.error}
          isReadOnly={disabled}
          fontSize={{ xs: "xs", lg: "sm" }}
          type={props.type}
          variant={props.variant || "filled"}
        />
        <InputRightElement
          width={props?.rightelementwidth}
          p={props?.rightelementpadding}
          m={props?.rightelementmargin}
        >
          {rightelement}
        </InputRightElement>
      </InputGroup>
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};
