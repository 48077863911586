import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsCardHeading } from "react-icons/bs";

import {
  Button,
 
} from "@karkhanaui/react";
import ContractsTable from "./ContractsTable";
const ContractsMain = () => {
  const navigate = useNavigate();
  return (
    <Box width="100%">
      <Divider />
      <Flex bg="#fff" p=".5rem" justifyContent="flex-end">
        <Button
          onClick={() => navigate("/createcontract")}
          m={"1rem 1rem 1rem 2em"}
          children="Create Contract"
          variant="ghost"
          border="1px solid #0087EE"
          color="#0087EE"
          _hover={{backgroundColor: '#0087ee10'}}
          _hover={{ opacity: ".8" }}
          leftIcon={<BsCardHeading />}
        />
      </Flex>
      <Box
        height="calc(100% - 3.5rem)"
        padding="1rem"
        display="flex"
        flexDirection="column"
      >
        <Tabs>
          <TabList>
            <Tab
              textStyle="primary.paragraph"
              fontWeight="semibold"
              fontSize="16px"
            >
              All Contracts
            </Tab>
            <Tab
              textStyle="primary.paragraph"
              fontWeight="semibold"
              fontSize="16px"
            >
              Draft
            </Tab>
            <Tab
              textStyle="primary.paragraph"
              fontWeight="semibold"
              fontSize="16px"
            >
              Submitted
            </Tab>
          </TabList>
          <Box mt="1rem" overflowY="scroll">
            <Flex alignItems="center" justifyContent="space-between">
              <InputGroup
                border="1px solid #BABCBE"
                borderRadius="8px"
                width="20rem"
              >
                <InputLeftAddon padding="5px">
                  <Select
                    variant="unstyled"
                    width="6rem"
                    // value={selectedOption}
                    // onChange={handleEvent}
                  >
                    <option value="quote_id">Status</option>
                    <option value="opportunity_nick_name">
                      Contract Title
                    </option>
                    <option value="opportunity_nick_name">Supplier</option>
                    <option value="opportunity_nick_name">Contract Type</option>
                    <option value="opportunity_nick_name">Created On</option>
                  </Select>
                </InputLeftAddon>
                <Input
                  placeholder={"Search"}
                  // onChange={(event) => setSearchQuery(event.currentTarget.value)}
                ></Input>
              </InputGroup>
            </Flex>
            <TabPanels>
              {/* first tab */}
              <TabPanel>
                <ContractsTable />
              </TabPanel>

              {/* secon tab */}
              <TabPanel>
                  Draft Table
                {/* <ContractsTable /> */}
              </TabPanel>

              {/* third tab */}
              <TabPanel>

                SubmittedTable
                {/* <ContractsTable /> */}
              </TabPanel>

            </TabPanels>
            {/* TODO: implement Table actions */}
          </Box>
        </Tabs>
        <Box width="100%">dummy data</Box>
      </Box>
    </Box>
  );
};
export default ContractsMain;
