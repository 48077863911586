import * as React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { BsXLg } from "react-icons/bs"

import { motion, AnimatePresence, PanInfo } from "framer-motion";

interface BottomSheetProps {
  isOpen: any;
  onClose: any;
  children: any;
}

export const BottomSheet = ({
  isOpen,
  onClose,
  children,
  ...props
}: any) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: "0%" }}
          exit={{ y: "100%" }}
          transition={{ type: "spring", damping: 30, stiffness: 400 }}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "white",
            height: "auto",
            maxHeight: "90vh",
            overflowY: "auto",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            borderTopLeftRadius: "1em",
            borderTopRightRadius: "1em",
          }}
        >
          <Box
            width="60px"
            height="6px"
            borderRadius="md"
            bgColor="gray.300"
            mx="auto"
            mt=".5rem"
            mb=".5rem"
          ></Box>
          <Flex pos="absolute" top="6" right="6" >
            <BsXLg size={"17px"} onClick={() => onClose()} />
          </Flex>
          <Box p={4} {...props}>{children}</Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};